import React from 'react';
import { LocationProvider } from './location/LocationContext';
import Map from './Map';



function App() {
    
  return (
    <LocationProvider>
       <Map />
    </LocationProvider>
  );
}

export default App;
