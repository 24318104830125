import { useState, useEffect, useMemo } from 'react';
import firebase from 'firebase/compat/app';
import app from './firebaseConfig';
import 'firebase/compat/firestore';
import { GeoFirestore } from 'geofirestore';

const db = app.firestore();
const geofirestore = new GeoFirestore(db);
const geocollection = geofirestore.collection('markups');

function useMarkups(currentLocation, refreshCache) {
  const [markups, setMarkups] = useState([]);
  const [isCacheSet, setIsCacheSet] = useState(false);  

  const optimizedMarkups = useMemo(() => markups, [markups]); // Use memoization to optimize rendering

  useEffect(() => {
    const fetchData = async () => {
     // console.log('fetchData called', refreshCache);
      if (isCacheSet && !refreshCache) return;

  //    console.log('fetchData called');

      const query = geocollection.near({
        center: new firebase.firestore.GeoPoint(currentLocation.lat, currentLocation.lng),
        radius: 70.4672
      });

      const value = await query.get();
      const fetchedMarkups = value.docs.map(doc => doc.data());

      setMarkups((prevMarkups) => {
        const combinedMarkups = [...prevMarkups, ...fetchedMarkups];
        localStorage.setItem('markups', JSON.stringify(combinedMarkups));
        localStorage.setItem('lastUpdate', new Date().toISOString());
        return combinedMarkups;
      });
    };

    const cachedMarkups = JSON.parse(localStorage.getItem('markups') || '[]');

    if (cachedMarkups.length > 0 && !isCacheSet && !refreshCache) {
   //   console.log('Setting cached markups');
      setMarkups(cachedMarkups);
      setIsCacheSet(true);
    } else if (currentLocation && currentLocation.lat && currentLocation.lng) {
      fetchData();
    }
  }, [currentLocation, refreshCache, isCacheSet]);

  return optimizedMarkups;
}

export default useMarkups;
