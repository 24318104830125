import React, {createContext, useState, useEffect } from 'react';

const LocationContext = createContext();

const LocationProvider = ({ children }) => {
  const [currentLocation, setCurrentLocation] = useState({ lat: null, lng: null });

  useEffect(() => {
    
    navigator.geolocation.getCurrentPosition((position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
    
      setCurrentLocation({ lat: latitude, lng: longitude });
    }, (error) => {  fetch('https://ipinfo.io/json?token=ce274b960770f1')
    .then(response => response.json())
    .then(data => {
      const [lat, lon] = data.loc.split(',');
    
      setCurrentLocation({
        lat: parseFloat(lat),
        lng: parseFloat(lon),
      });
    })
    .catch(error => {
      console.error('Error fetching IP geolocation:', error);
    })});
  }, []);

  if (!children) return null; // Return null or a loading spinner if children is undefined

  return (
    <LocationContext.Provider value={[currentLocation, setCurrentLocation]}>
      {children}
    </LocationContext.Provider>
  );
};

export { LocationContext, LocationProvider };
