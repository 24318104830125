import firebase from 'firebase/compat/app';

  const firebaseConfig = {
      apiKey: "AIzaSyAEvULEHToW3GRIK5B7jkOZdcWMuS0BVB0",
      authDomain: "kiddoki.firebaseapp.com",
      projectId: "kiddoki",
      storageBucket: "kiddoki.appspot.com",
      messagingSenderId: "30023816877",
      appId: "1:30023816877:web:1c3ed8ffe4fb1996319824",
      measurementId: "G-PPV8VZX3PG"
    };
    
const app = firebase.initializeApp(firebaseConfig);


export default app;
