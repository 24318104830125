// src/App.js
import React, { useState, useRef, useContext } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, Popup, useMapEvents } from 'react-leaflet';
import { LocationContext } from './location/LocationContext';
import useMarkups from './markup';
import 'leaflet/dist/leaflet.css';
import './App.css';

function Map() {
 
  const [currentLocation, setCurrentLocation] = useContext(LocationContext);
  const [refreshCache, setRefreshCache] = useState(false);
  const markups = useMarkups(currentLocation, refreshCache);
  const [selectedCategory, setSelectedCategory] = useState("Restaurant");
  const [zoom, setZoom] = useState(14);
  const markerRefs = useRef([]);
  if (!currentLocation || !currentLocation.lat || !currentLocation.lng){
     return;   
  }
  const position = [currentLocation.lat, currentLocation.lng];
  const filteredMarkups = selectedCategory ? markups.filter(markup => markup.category === selectedCategory) : markups;
  const onMarkerClick = (index) => {
    // When a marker or tooltip is clicked, open the popup
    markerRefs.current[index].openPopup();
  };

   

  return (
    <div className="App">
       <div className="top-banner">
            <div className="banner-content">
                <span className="brand-name">Kiddoki</span>
              <div className="bubble-container">
                <div className={`bubble ${selectedCategory === 'Restaurant' ? 'selected' : ''}`} onClick={() => setSelectedCategory('Restaurant')}>
                  Restaurants
                </div>
                <div className={`bubble ${selectedCategory === 'Park' ? 'selected' : ''}`} onClick={() => setSelectedCategory('Park')}>
                  Parks
                </div>
             </div>
            </div>
         </div>
        
      <MapContainer 
        center={position} 
        zoom={zoom} 
        style={{ height: "100vh", width: "100%" }}
      
        >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {filteredMarkups.map((markup, index) => (
        <Marker
        ref={(ref) => { markerRefs.current[index] = ref; }}
         key={index} 
         position={[markup.coordinates.latitude, markup.coordinates.longitude]}
          >
        {zoom >= 12 && (
          <Tooltip permanent interactive={true} eventHandlers={{
            click: (e) => onMarkerClick(index)
          }}>
           {markup.name}
          </Tooltip>
        )}
          <Popup>
          <strong>{markup.name}</strong><br/>
                {markup.address}<br/>
                <a 
                  href={`https://www.google.com/maps/dir/?api=1&destination=${markup.coordinates.latitude},${markup.coordinates.longitude}&travelmode=driving`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                    Get Directions
                </a>
        </Popup>
        </Marker>
       ))}
        <ZoomHandler setZoom={setZoom} />
        <MoveHander currentLocation={currentLocation} setCurrentLocation={setCurrentLocation} setRefreshCache={setRefreshCache}/>
      </MapContainer>
    </div>
  );
}

function ZoomHandler({ setZoom }) {
    const map = useMapEvents({
      zoomend: () => {
        setZoom(map.getZoom());
      }
    });
    
    return null;
  }

  function MoveHander({currentLocation, setCurrentLocation, setRefreshCache}) {
    const map = useMapEvents({
      moveend: () => {
        const center = map.getCenter();
        const newLat = center.lat;
        const newLng = center.lng;
        
        const distance = getDistanceBetweenTwoPoints(
          newLat,
          newLng,
          currentLocation.lat,
          currentLocation.lng
        );
        
        if (distance >= 40) { // check if distance is greater or equal to 40 km
          const newLocation = { lat: newLat, lng: newLng };
          setRefreshCache(true);
          setCurrentLocation(newLocation);
        }
      
      }
    });
    return null;
  }

  
  // Function to calculate distance between two points based on latitude and longitude
  function getDistanceBetweenTwoPoints(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2); 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const distance = R * c; // Distance in km
    return distance;
  }
  
  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }

export default Map;
